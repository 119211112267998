const leftNavPackagingTeam = [
  {
    order: 1,
    name: "Home",
    route: "/packagingTeamHome",
    expanded: false,
  },
  {
    order: 2,
    name: "Order Details",
    route: "/packagingTeamHome/orderDetails",
    expanded: false,
  },
  {
    order: 3,
    name: "Workspace Order Details",
    route: "/packagingTeamHome/schoolOrderDetails",
    expanded: false,
  },
  {
    order: 7,
    name: "Workspace Wise Order Exporting",
    route: "/packagingTeamHome/schoolOrderPdfExport",
    expand: false,
  },
  {
    order: 4,
    name: "Reports",
    route: "/packagingTeamHome/reports",
    expand: false,
  },
  {
    order: 5,
    name: "Users Birthdays",
    route: "/packagingTeamHome/kidsBirthdays",
    expand: false,
  },
  {
    order: 6,
    name: "Future Users Birthdays",
    route: "/packagingTeamHome/futurebirthdays",
    expand: false,
  },
  {
    order: 7,
    name: "22 Days Subscribers",
    route: "/packagingTeamHome/subscriber",
    expand: false,
  },
  {
    order: 8,
    name: "Route Wise Reports",
    route: "/packagingTeamHome/routeWiseReports",
    expand: false,
  },
  {
    order: 9,
    name: "Route Wise Orders",
    route: "/packagingTeamHome/routeWiseOrders",
    expand: false,
  },
  {
    order: 10,
    name: "Order Tracking",
    route: "/packagingTeamHome/orderTracking",
    expand: false,
  },
  {
    order: 11,
    name: "Missing Box Report",
    route: "/packagingTeamHome/missingBoxReport",
    expand: false,
  },
  {
    order: 12,
    name: "Notifications",
    route: "/packagingTeamHome/notifications",
  },
  {
    order: 13,
    name: "Van Tracking",
    route: "/packagingTeamHome/vanTracking",
    expand: false,
  },
  {
    order: 14,
    name: "Workspace OrderPdf Export",
    route: "/packagingTeamHome/schoolOrderPdfExport",
    expand: false,
  },

  // {
  //   order: 11,
  //   name: "Mark Order Dispatched",
  //   route: "/packagingTeamHome/markOrderDispatched",
  //   expanded: false,
  // },
  // {
  //   order: 12,
  //   name: "Mark Order Delivered",
  //   route: "/packagingTeamHome/markOrderDeliverd",
  //   expanded: false,
  // },
];

export default leftNavPackagingTeam;
