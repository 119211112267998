export const isProtected = (route) => {
  let unprotectedRoutes = [
    '/login','/login/success',
    '/test',
    '/workspaceDetails/[id]',
    '/admin/schoolDetails/[id]'
  ];


  return !unprotectedRoutes.includes(route);
};