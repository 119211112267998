const leftNavFinanceAdmin = [
  {
    order: 1,
    name: "Support Team",
    route: "/financeHome",
    expanded: false,
    icon: <i className="mdi mdi-bookshelf"></i>,
  },
  {
    order: 2,
    name: "Cashback Transactions",
    route: "/financeHome/transactionTable",
    expanded: false,
    icon: <i className="mdi mdi-bookshelf"></i>,
  },
  {
    order: 6,
    name: "Refund Transactions",
    route: "/financeHome/refund-transactions",
    expanded: false,
    icon: <i className="mdi mdi-bookshelf"></i>,
  },
  {
    order: 10,
    name: "Approve Refund Request",
    route: "/financeHome/refund-requests",
    expanded: false,
    icon: <i className="mdi mdi-bookshelf"></i>,
  },
  {
    order: 7,
    name: "Referral Setting",
    route: "/financeHome/referral-setting",
    expanded: false,
    icon: <i className="bx bx-cog"></i>,
  },
  {
    order: 9,
    name: "Refarral History",
    route: "/financeHome/referral-history",
    expanded: false,
    icon: <i className="mdi mdi-bookshelf"></i>,
  },
  {
    order: 10,
    name: "Rewards",
    route: "/financeHome/rewards",
    expanded: false,
    icon: <i className="mdi mdi-bookshelf"></i>,
  },
  {
    order: 4,
    name: "Workspace Order Report",
    route: "/financeHome/schoolOrderReports",
    expanded: false,
    icon: <i className="mdi mdi-bookshelf"></i>,
  },
  {
    order: 8,
    name: "Workspace B2B Order Report",
    route: "/financeHome/schoolb2bReport",
    expanded: false,
    icon: <i className="mdi mdi-bookshelf"></i>,
  },
  {
    order: 9,
    name: "Coupons",
    route: "/financeHome/coupons",
    expanded: false,
    icon: <i className="mdi mdi-bookshelf"></i>,
  },
  {
    order: 10,
    name: "MIS Reports",
    expanded: true,
    children: [
      {
        order: 1,
        name: "Menu Reports",
        route: "/financeHome/menu-reports",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 2,
        name: "Daily Sales Reports",
        route: "/financeHome/dsr-reports",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 3,
        name: "School Performance Reports",
        route: "/financeHome/school-performance-reports",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 4,
        name: "Age Group Reports",
        route: "/financeHome/agegroup-reports",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 5,
        name: "Finance Reports",
        route: "/financeHome/finance-reports",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
    ],
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    order: 11,
    name: "Coupon Code Analytics",
    route: "/financeHome/coupon-analytics",
    expanded: false,
    icon: <i className="mdi mdi-bookshelf"></i>,
  },
];

export default leftNavFinanceAdmin;
