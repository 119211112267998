import React from 'react';
import SidebarContent from './SidebarContent';
import { basePath } from 'next.config';

const Sidebar = (props) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        {/* <div className="navbar-brand-box">
          <div className="logo logo-light">
            <span className="logo-sm">
              <img src={basePath + "/images/logo.svg"} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img
                src={basePath + "/images/MB_horizontal_logo.png"}
                alt="logo"
                height="35"
                width="200"
              />
            </span>
          </div>
        </div> */}
        <div style={{ display: 'flex', alignItems:'center', marginTop:'-3.5rem', padding:'1rem' }}>
          <span>              
            <img src={basePath + '/images/cac.png'} alt="" height="40" />
          </span>
          <div style={{marginLeft: '1rem', color:'white', fontSize: '2em'}}>COMBO@CO</div>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
