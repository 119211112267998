import EmptyLayout from './EmptyLayout';
import Layout from './Layout';
const LayoutConfig = [
  {
    path:'/workspaceDetails/[id]',
    layout:EmptyLayout
  },
  {
    path:'/admin/schoolDetails/[id]',
    layout:EmptyLayout
  },
  {
    path: '/login',
    layout: EmptyLayout,
  },
  {
    path: '/',
    layout: Layout,
  },
  {
    path: '',
    layout: EmptyLayout,
  },
];

const getLayout = (path) => {
  let config = LayoutConfig.find((conf) => path.includes(conf.path));
  if (config) return config.layout;
  else return EmptyLayout;
};

export { getLayout };
